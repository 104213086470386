.App {
  text-align: center;
  color: gray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  height: -webkit-fill-available;
}

.App h1 {
  margin: 0 20px;
}

input[type="text"],
label {
  margin: 0 8px 0 0;
}

canvas {
  margin: 30px;
}

.App .inputs {
  display: flex;
}

.App p {
  font-size: 16px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer {
  margin-top: auto;
  padding-bottom: 12px;
}
